import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Container,
    Row,
    Col,
  } from "reactstrap";

  import "./style.css"

const ToolCards = () => {
    return (
        <>
      <div className="section" id="cards">
        <Container className="tim-container">
          <div className="title text-center">
            <h1>Our Vehicles Sell Fast!</h1>
          </div>

          <Row>
            <Col md="4">
            <Card className="h-200 text-center">
                <CardBody>
                <img
                    alt="..."
                    // className="image-left-pull-right"
                    src={require("assets/images/car-list/OVSF-car-01.jpg")}
                  />
                  
                  <div className="card-icon">
                   
                  </div>
                  
                    <CardTitle tag="h4">
                    FIND A VEHICLE
                    </CardTitle>
                  
                  <div className="card-icon">
                   
                   </div>
                  <CardFooter className="text-center">
                 
                  <Button
                      className="btn-round card-link"
                      color="danger"
                      to="/inventory"
                      tag={Link}
                     
                    >
                      <i className="fa fa-book mr-1" />
                      SHOP NOW
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
            <Card className="h-200 text-center">
                <CardBody>
                <img
                    alt="..."
                    // className="image-left-pull-right"
                    src={require("assets/images/car-list/OVSF-truck-01.jpg")}
                   
                  />
                   
                    <div className="card-icon">
                   
                  </div>
                    <CardTitle tag="h4">
                    GET APPROVED NOW
                    </CardTitle>
                  <div className="card-icon">
                   
                  </div>
                 
                  <CardFooter className="text-center">
                    <Button
                      className="btn-round card-link"
                      color="danger"
                      to="/credit-application"
                      tag={Link}
                    >
                      <i className="fa fa-book mr-1" />
                      START
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="h-200 text-center">
              <CardBody >
                 <img
                    alt="..."
                    // className="image-left-pull-right"
                    src={require("assets/images/car-list/OVSF-suv-01.jpg")}
                  />
               
                   
                    <div className="card-icon">
                   
                  </div>
                 
                    <CardTitle tag="h3">
                    SELL YOUR CAR
                    </CardTitle>
                  
                  <div className="card-icon">
                   
                   </div>
                  
                  <CardFooter className="text-center">
                    <Button
                      className="btn-round card-link"
                      color="danger"
                      to="/sell"
                      tag={Link}
                    >
                      <i className="fa fa-book mr-1" />
                      SELL NOW
                    </Button>
                  </CardFooter>
                  
                </CardBody>
              </Card>
            </Col>
          </Row>
       
       </Container>
       </div>   
    </>
    );
};

export default ToolCards;