import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

import AboutUsHeader from "./AboutUsHeader.js";
import ToolCards from "Home/ToolCards.js";

import config from "_config"
import "./style.css"
function AboutUs() {
  document.documentElement.classList.remove("nav-open");
  
  return (
    <>

      {/* <AboutUsHeader /> */}

        <div className="section first-level"
         style={{
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "url(" +
            require("assets/images/background/auto-mart-header-bg-1680x900.jpg") +
            ")",
        }}>
          <Container>
          <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h1 className="title">About Us</h1>
                <h5 className="description">
                {config.company} is a family-owned 
            and operated Dealership that can give our customers 
            that personal touch that you can't find elsewhere. 
            Located on Sackville drive in Lower Sackville, we 
            might be the friendliest car dealership you've ever 
            encountered.
                </h5>

          
              </Col>
            </Row>
            
           
            <Row>
            
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardBody>
                    <div>
                      
                        <img
                          alt="..."
                          src={require("assets/images/team/pexels-photo-1197132.jpeg")}
                        />
                        <CardTitle tag="h4">Sales Manager</CardTitle>
                      
                    </div>
                    <p className="card-description text-center">
                     
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                       className="btn-rnd "
                       color="success"
                      href="tel:9028654495"
                      
                    >
                      Click to Call
                    </Button>
                    
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardBody>
                    <div>
                     
                        <img
                          alt="..."
                          src={require("assets/images/team/pexels-photo-937481.jpeg")}
                        />
                         <CardTitle tag="h4">Sales Associate</CardTitle>
                        
                      
                    </div>
                    <p className="card-description text-center">
                     
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                  <Button
                       className="btn-rnd "
                       color="success"
                      href="tel:9028654495"
                      
                    >
                      Click to Call
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <CardBody>
                    <div>
                      
                        <img
                          alt="..."
                          src={require("assets/images/team/adult-beautiful-blonde-blur-324658.jpg")}
                        />
                       <CardTitle tag="h4">Sales Associate</CardTitle>
                      
                    </div>
                    <p className="card-description text-center">
                     
                     </p>
                  </CardBody>
                  <CardFooter className="text-center">
                  <Button
                      className="btn-rnd "
                      color="success"
                      href="tel:9028654495"
                      
                    >
                      Click to Call
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
           
          </Container>
        </div>
     
   <ToolCards />
    </>
  );
}

export default AboutUs;
