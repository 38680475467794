import { all } from 'redux-saga/effects';

// import {websiteLoginUser, websiteLogoutUser, websiteForgotPassword} from './Auth';
import {websiteLoad} from './Website';

// import {customerLoad} from './Customers';
// import {garageLoad} from './Garage'
// import {serviceRequestLoad} from './Service'
import LandingPage from './LandingPage'
import LeadSource from "./LeadSource"
import YearMakeModel from './YearMakeModel'
import YearMakeModeTrimSelect from "./YearMakeModeTrimSelect"
import Paginate from './Paginate'
import Inventory from './Inventory'
import Leads from "./Leads"
import InventorySelect from "./InventorySelect"
import ResetPage from './ResetPage'
import Weblead from './weblead'
import Website from './Website'

export function* rootSaga() {
  yield all([
    // fetchWebsiteDetails(),
    // websiteRequest(),
    // websiteLoadUser(),
    // websiteLoginUser(),
    // websiteLogoutUser(),
    // websiteForgotPassword(),
    websiteLoad(),
    LeadSource(),
    LandingPage(),
    InventorySelect(),
    Leads(),
    // customerLoad(),
    // garageLoad(),
    // serviceRequestLoad(),
    Paginate(),
    Inventory(),
    ResetPage(),
    Weblead(),
    Website(),
    YearMakeModel(),
    YearMakeModeTrimSelect(),
  ]);
}

export default rootSaga