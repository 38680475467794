import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import config from "_config";
function SectionBlog() {
  return (
    <>
      <div
        className="section first-level"
        id="blogs"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "url(" +
            require("assets/images/background/auto-mart-header-bg-1680x900.jpg") +
            ")",
        }}
      >
        {/* ********* BLOGS 1 ********* */}
        <div className="blog-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title">Tips and Advice</h2>
                <br />
                <p>
                  An auto loan with {config.company} can make it possible for
                  you to enjoy a dependable pre-owned car, truck, or SUV. If you
                  are currently unable to come into the dealership, you can
                  easily start the car buying process online. With our
                  application, you can apply for financing from the comfort of
                  your own home. We are giving you a chance to find the
                  necessary documents needed to complete your application for
                  pre-approval.
                </p>

                <Card className="card-plain card-blog" id="no-money-down">
                  <Row>
                    <Col md="5" className="mt-5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/images/background/leonard-cotte.jpg")}
                        />
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">
                          Buying a Used Car with No Money Down
                        </CardTitle>
                        <p className="card-description">
                          Get the best financing options available for poor
                          credit car buyers as soon as possible. If you choose
                          to go with the option of starting online, once you are
                          pre-approved, you will be asked to bring in items to
                          verify e info that was provided on the application.
                          When you get to our lot, you will meet with one of the
                          experienced finance managers, present your proof of
                          employment, residency, and insurance. From there, you
                          will be allowed to test drive the cars that fit into
                          our lending partner’s financing plans. Once you have
                          found a vehicle that you are prepared to purchase, you
                          sign the loan documents and agree to the terms.
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-plain card-blog" id="bankruptcy">
                  <Row>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">
                          How to Get a Car Loan After Bankruptcy
                        </CardTitle>
                        <p className="card-description">
                          Even though you are looking to get your credit cleaned
                          up, you may still be considered a higher risk with bad
                          credit. There are programs offered by{" "}
                          {config.short_co} Financing &amp; Auto Sales that will
                          work with you to obtain an auto loan, even if you are
                          going through a bankruptcy or are currently completing
                          one. Typically, a co-signer will be able to assist you
                          with meeting our lending partner’s loan requirements.
                        </p>
                        <p className="author">
                          A Co-Applicant can be any of the following:{" "}
                          <b>
                            Spouse | Parents | Significant Other | Grandparents
                            | Friend
                          </b>
                        </p>
                      </CardBody>
                    </Col>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/images/background/leonard-cotte.jpg")}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-plain card-blog" id="bad-credit">
                  <Row>
                    <Col md="5" className="mt-5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/images/background/por7o.jpg")}
                        />
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">
                          Get a Car Loan with Bad Credit
                        </CardTitle>
                        <p className="card-description">
                          Even if your credit has been shattered from bad
                          credit, poor credit, or no credit history at all, it
                          is essential to note that there are other ways that
                          you can obtain a loan and get the car, truck, or SUV
                          that you need, and that fits your overall budget.
                          There are many things to consider before, during, and
                          after bankruptcy, but {config.company} is here to help
                          you along the way. A vehicle purchase is a major
                          financial decision, so making sure that you are in the
                          best position is vital to the process.
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-plain card-blog" id="best-loan">
                  <Row>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">
                          The Best Auto Loan For You
                        </CardTitle>
                        <p className="card-description">
                          Many options can fit different car buyers. Everyone’s
                          situation is different, so if you have been turned
                          down at other dealerships, we can help you regardless
                          of your credit. Even if you don’t qualify for
                          traditional vehicle financing,
                          {config.company} can work with you despite your
                          financial past. Our large network of lenders can give
                          you the best auto loan available for your credit
                          situation.
                        </p>
                      </CardBody>
                    </Col>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/images/background/leonard-cotte.jpg")}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-plain card-blog" id="0-down">
                  <Row>
                    <Col md="5" className="mt-5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/images/background/leonard-cotte.jpg")}
                        />
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">No Money Down Auto Loans</CardTitle>
                        <p className="card-description">
                          Not everyone can walk into a dealership with a down
                          payment in hand. At {config.company}, you can apply
                          for no money down pre-owned vehicle loans. These
                          financing auto loans let you purchase a reliable and
                          durable used car without putting anything down before
                          driving off our car lot. No matter what type of credit
                          you have, you can qualify for one of these financing
                          forms. Get approved today with our quick and easy
                          online form. You are only moments away from your
                          pre-owned car, truck, or SUV purchase. Get an
                          outstanding loan with {config.company} and begin the
                          journey to building, establishing, and repairing your
                          overall financial well-being.
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-plain card-blog" id="used-car-loan">
                  <Row>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">
                          Advantages Of A Used Car Loan
                        </CardTitle>
                        <p className="card-description">
                          If you are in the market for a vehicle, there is a
                          good chance that you have at least considered buying a
                          previously-owned vehicle as opposed to buying brand
                          new. After all, you can easily save thousands or even
                          tens of thousands of dollars just by purchasing a car
                          that is a few years old and has less kilometers on it.
                          A brand new automobile drastically depreciates the
                          moment you drive it off the lot. A high-quality,
                          previously-owned vehicle can easily run close to
                          $10,000. This is an amount that can be financed once
                          you are approved directly through the lending partners
                          of {config.company}. Rather than applying for funding
                          through a bank, you may save yourself a lot of time by
                          applying with {config.company}
                          Sales. We are responsible for providing solutions to
                          residents of Atlantic Canada every day. Before you
                          know it, you could be well on your way to purchasing
                          an automobile that perfectly fits your budget and
                          needs. The process can start for you today.{" "}
                          {config.company} has your approval waiting for you
                          regardless of your specific credit score or other
                          aspects of your financial history. Let us work hard on
                          getting you the auto loan car financing that you
                          deserve.
                        </p>
                      </CardBody>
                    </Col>
                    <Col md="5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/images/background/por7o.jpg")}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-plain card-blog" id="qualify-car-loan">
                  <Row>
                    <Col md="5" className="mt-5">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/images/background/leonard-cotte.jpg")}
                        />
                      </div>
                    </Col>
                    <Col md="7">
                      <CardBody>
                        <CardTitle tag="h3">
                          How To Easily Qualify for a Car Loan
                        </CardTitle>
                        <p className="card-description">
                          {config.company} minimizes the potential stress,
                          hassle, and discouragement of purchasing a vehicle
                          with bad credit. With our auto financing programs, it
                          is easy to qualify for previously owned vehicle loans
                          throughout Atlantic Canada. Buyers can pre-qualify
                          their financing options and have much higher success
                          rates in bettering their credit profiles. Once you
                          have submitted your online application, we will take a
                          look at the information that you have provided to us
                          and then find loan opportunities that match your
                          position. There are cases where a customer may even be
                          required to provide a co-signer or provide collateral
                          to secure some loans. A loan from {config.company} may
                          enable you to buy a new car rather than a used car. If
                          you have been searching for the perfect vehicle and
                          have been struggling with denials and turn-downs when
                          wanting to buy a newer or second-hand car, then car
                          loans for bad credit may give you the satisfaction of
                          achieving this goal. Our understanding and
                          professional loan specialists are waiting for your
                          application to begin the process of helping you learn
                          about easier qualifying car loans to facilitate your
                          next car purchase.
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END BLOGS 1 ********* */}
      </div>
    </>
  );
}

export default SectionBlog;
