import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

import config from "_config"

function CreditCenter() {
  document.documentElement.classList.remove("nav-open");
  
  return (
    <>


        <div className="section first-level" id="features"
      style={{
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "url(" + require("assets/images/background/auto-mart-header-bg-1680x900.jpg") + ")"
      }}
      >
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center title" md="6">
                  <h2>Credit Center</h2>
                  
                </Col>
              </Row>
              <div className="article">
                <Row>
                  <Col className="ml-auto mr-auto" md="8">
                    <Card className="card-blog card-plain text-center">
                      <div className="card-image">
                           <img
                            alt="..."
                            className="img img-raised"
                            src={require("assets/images/background/bruno-abatti.jpg")}
                          />
                        
                      </div>
                      <CardBody>
                        
                        
                          <CardTitle tag="h3">
                          Regardless of your credit history or even if you’ve
                           been turned down for a car loan before, we can help 
                           get you approved.
                          </CardTitle>
                      
                        <div className="card-description">
                          <p>
                          At {config.company}, 
                          we have experience getting people approved:
</p>
<p>
· Write-Offs and Closed Accounts

· No Credit

· History of Bad Credit

· Multiple Collections

· Consumer Proposal

· Previous Bankruptcy

· New to Canada with No Credit

· Previous Repossession

· Student Loans in Arrears

· Missed Credit Card and Loan Payments

In-House Financing Program

At {config.company}, we understand that shoppers
 can find themselves in a difficult financial situation. 
 Our In-House Financing Program can help with alternatives 
 like our Buy Here Pay Here option.

Family owned and operated {config.company} can 
give our customers that personal touch that you can’t find 
elsewhere. We look forward to earning your trust and 
business on your next pre-owned vehicle
                          </p>
                        </div>
                      </CardBody>
                      
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
      

    </>
  );
}

export default CreditCenter;
