import React from "react";
import { Link } from "react-router-dom";

// // JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";
// core components
import "./nav.css";

import config from "_config"


function WhiteNavbar() {


  return (
    <>
    
      <Navbar className="fixed-top" expand="lg" id="navbar-main">
        <Container >
        <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" 
            className="logo-image" to="/" tag={Link}>
            <img 
            src={require('assets/images/logo/company-logo.png')} 
            alt={config.company} />
              
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
            {config.company}
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
               
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
         
          <Collapse navbar >
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  Vehicles
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/inventory" tag={Link}>
                    Used Inventory
                  </DropdownItem>
                  
                  <DropdownItem
                    to="/test-drive" tag={Link}
                  >
                    Book a Testdrive
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  Cash for Cars
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/sell" tag={Link}>
                    
                    Trade Appraisal
                  </DropdownItem>
                 
                </DropdownMenu>
              </UncontrolledDropdown>
             
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  Finance
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/financial-service" tag={Link}>
                    
                    Financial Services
                  </DropdownItem>
                  {/* <DropdownItem to="/credit-center" tag={Link}>
                    
                    Credit Centre
                  </DropdownItem> */}
                  <DropdownItem to="/credit-application" tag={Link}>
                    
                    Secure Credit Application
                  </DropdownItem>
                  {/* <DropdownItem to="/tips-advice" tag={Link}>
                    
                    Tips and Advice
                  </DropdownItem> */}
                 
                </DropdownMenu>
              </UncontrolledDropdown>
             

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  About {config.short_co}
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/why-choose-us" tag={Link}>
                    Why Choose {config.short_co}
                  </DropdownItem>
                  <DropdownItem to="/about-us" tag={Link}>
                    About Us
                  </DropdownItem>
                  <DropdownItem to="/customer-feeback" tag={Link}>
                    Customers Feedback
                  </DropdownItem>
                  <DropdownItem to="/team" tag={Link}>
                    Meet the Team
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
             {/*  <Nav.Link eventKey="link-2">Contact Us</Nav.Link>*/}
              <NavLink to="/contact" tag={Link}>Contact Us</NavLink>
              </NavItem> 

              
              <NavItem>
                <Button
                  className="btn-round d-sm-none"
                  color="danger"
                  size="sm"
                  to="/credit-application" tag={Link}
                >
                  Apply for Credit
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        
        
        </Container>
      </Navbar>
    </>
  );
}

export default WhiteNavbar;
